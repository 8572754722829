<script setup>
import { ref, onMounted } from 'vue'
import emblaCarouselVue from 'embla-carousel-vue'
import Autoplay from 'embla-carousel-autoplay'
import { useEmblaParallax } from '@/composables/emblaParallax'

const [emblaRef, emblaApi] = emblaCarouselVue({ loop: true, container: '.embla__container' }, [Autoplay()]);

function logSlidesInView(emblaApi) {
  console.log(emblaApi.slidesInView())
}

onMounted(() => {
  if (emblaApi.value) {
    useEmblaParallax(emblaApi.value)
  }
})

function prevSlide() {
  emblaApi.value.scrollPrev()
}

function nextSlide() {
  emblaApi.value.scrollNext()
}

const url = 'https://bluffcityfc.com/img/carousel/'
const picUrls = ref( Array.from({length: 5}, (_,i) => url + `${i + 1}.jpg`))
</script>


<template>
  <div class="embla" ref="emblaRef">
    <div class="embla__viewport">
      <div class="embla__container">
        <div class="embla__slide" v-for="url in picUrls" :key="url">
          <div class="embla__parallax">
            <div class="embla__parallax__layer">
              <img
                class="embla__slide__img embla__parallax__img"
                :src="url"
                alt="Your alt text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-buttons">
      <button class="carousel-button left" @click="prevSlide"><</button>
      <button class="carousel-button right" @click="nextSlide">></button>
    </div>
  </div>
</template>


<style scoped lang="stylus">
.embla
  margin-top: 100px

.carousel-button
  position: absolute
  top: 400px
  &.right
    right: 10px
  &.left
    left: 10px
  
@media screen and (max-width: 699px) 
  .carousel-button
    top: 300px
</style>