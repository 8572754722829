import { createApp, createSSRApp } from 'vue'
import * as Vue from 'vue' // in Vue 3
import { createPinia } from 'pinia'
import router from './router'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import './css/style.css'
import './css/embla.css'

const pinia = createPinia()
const app = createApp(App)
app.use(router)
  .use(pinia)
  .use(VueAxios, axios)

router.isReady().then(() => app.mount('#app'))
